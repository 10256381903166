import { message } from "antd";
import { useMutation } from "react-query";

import { updateRetailPayment } from "../graphql/mutations/updateRetailPayment";
import { PaymentMethod, PaymentType } from "./types";
import { callGql } from "../utils/apiClient";
import { domains } from "../constants";
import { usePaymentDetails } from "./hooks";
import { SuccessfulPayment } from "../adapters";

type UpdateSuccessfulPaymentEntity = {
  externalId: string;
  accountingDate: string;
  type: PaymentMethod;
};

export const mapSuccessfulPaymentToUpdateEntity = (
  payment: SuccessfulPayment
): UpdateSuccessfulPaymentEntity => ({
  externalId: payment.externalId,
  accountingDate: payment.accountingDate,
  type: payment.paymentType,
});

const getFieldsToUpdate = <T extends Record<string, unknown>>(
  initialValues: T,
  newValues: T
): Partial<T> =>
  Object.entries(newValues).reduce(
    (prev, [key, newValue]) =>
      newValue === initialValues[key as keyof T]
        ? prev
        : { ...prev, [key]: newValue },
    {}
  );

export const useUpdateSuccessfulPayment = (id?: string) => {
  const { data: initialValues } = usePaymentDetails(PaymentType.Successful);

  return useMutation(async (payment: SuccessfulPayment) => {
    const values = initialValues
      ? getFieldsToUpdate(
          mapSuccessfulPaymentToUpdateEntity(initialValues),
          mapSuccessfulPaymentToUpdateEntity(payment)
        )
      : mapSuccessfulPaymentToUpdateEntity(payment);
    const { errors } = await callGql({
      query: updateRetailPayment,
      variables: {
        payment: values,
        id,
      },
      domain: domains.PAYMENTS,
    });

    if (errors.length) {
      throw errors[0]?.message;
    }
    message.success("Payment was updated successfully");
  });
};
