import useUpdateEffect from "react-use/lib/useUpdateEffect";
import usePreviousDistinct from "react-use/lib/usePreviousDistinct";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { PAGE_SIZE } from "../constants";
import { PaymentOverviewEntity, usePayments } from "./PaymentsOverview/hooks";
import { InboundsFilter } from "./PaymentsOverview/PaymentsOverviewFilter";

const hasPrev = (currentPosition: number, currentPage: number) =>
  currentPosition !== -1 && !(currentPosition === 0 && currentPage === 1);

const hasNext = (
  currentPosition: number,
  items: unknown[],
  currentPage: number,
  totalPages: number
) =>
  currentPosition !== -1 &&
  (currentPosition !== items.length - 1 || totalPages !== currentPage);

const getTotalPages = (totalItems: number, itemsPerPage: number) =>
  Math.ceil(totalItems / itemsPerPage);

const emptyArray: PaymentOverviewEntity[] = [];
export const usePaymentDetailsNavigation = (
  onNavigate: (payment: PaymentOverviewEntity, page: number) => void
) => {
  const history = useHistory<
    { filter?: InboundsFilter; page?: number } | undefined
  >();

  const [currentPage, setCurrentPage] = useState(
    +(history.location.state?.page ?? 1)
  );
  const { id: currentPaymentId } = useParams<{ id: string }>();
  const { data: payments, refetch: refetchPayments } = usePayments(
    history.location.state?.filter ?? {},
    currentPage
  );

  const items = payments?.entities ?? emptyArray;

  const currentPaymentIndex = items.findIndex((i) => i.id === currentPaymentId);

  const totalPages = getTotalPages(payments?.totalEntityCount ?? 0, PAGE_SIZE);

  const previousPage = usePreviousDistinct(currentPage);
  useUpdateEffect(() => {
    (async () => {
      const res = await refetchPayments();
      if (typeof previousPage === "number") {
        const itemToNavigate =
          currentPage > previousPage
            ? res.data?.entities[0]
            : res.data?.entities?.[res.data?.entities.length - 1];

        if (itemToNavigate) {
          onNavigate(itemToNavigate, currentPage);
        }
      }
    })();
  }, [currentPage]);

  const goToNext = useCallback(() => {
    const nextIndex = (currentPaymentIndex ?? 0) + 1;
    if (items[nextIndex]) {
      onNavigate(items[nextIndex], currentPage);
    } else if (currentPage + 1 <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, currentPaymentIndex, items, onNavigate, totalPages]);

  const goToPrev = useCallback(() => {
    const prevIndex = currentPaymentIndex - 1;
    if (items[prevIndex]) {
      onNavigate(items[prevIndex], currentPage);
    } else if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, currentPaymentIndex, items, onNavigate]);

  return useMemo(
    () => ({
      hasPrev: hasPrev(currentPaymentIndex, currentPage),
      goToPrev,
      hasNext: hasNext(currentPaymentIndex, items, currentPage, totalPages),
      goToNext,
    }),
    [currentPage, currentPaymentIndex, goToNext, goToPrev, items, totalPages]
  );
};
