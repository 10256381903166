import { PaymentType } from "./../../types";
import { InboundsFilter } from "../PaymentsOverviewFilter";
import { PAGE_SIZE } from "../../../constants";
import { callGql } from "../../../utils/apiClient";
import { configByPaymentType } from "./constants";
import {
  FetchOtherPaymentsResponse,
  FetchSuccessfulPaymentsResponse,
  FetchUnidentifiedPaymentsResponse,
} from "./types";
import { formatISODateTime, addOneDayAndFormatISO } from "./utils";
import { fetchOrderNumbers } from "./fetchOrderNumbers";
import { orderNumbersAdapter } from "./orderNumbersAdapter";

const getOrderIds = (data: FetchSuccessfulPaymentsResponse): string[] =>
  data.searchRetailPayments.entities
    .map(({ orderId }) => orderId)
    .filter(Boolean);

export const fetchPayments = async (
  filter: InboundsFilter,
  page: number,
  pageSize = PAGE_SIZE
): Promise<
  | FetchUnidentifiedPaymentsResponse
  | FetchSuccessfulPaymentsResponse
  | FetchOtherPaymentsResponse
> => {
  const filters = [
    { op: "EQ", field: "countryCode", value: filter.country },
    { op: "EQ", field: "creationSource", value: filter.creationSource },
    {
      op: "ILIKE",
      field: "externalId",
      value: filter.externalId && `%${filter.externalId}%`,
    },
    {
      op: "ILIKE",
      field: "orderNumber",
      value: filter.orderNumber && `%${filter.orderNumber}%`,
    },
    { op: "EQ", field: "category", value: filter.category },
    { op: "EQ", field: "status", value: filter.status },
    {
      op: "ILIKE",
      field:
        filter.paymentType === PaymentType.Unidentified
          ? "accountHolder"
          : "holder",
      value: filter.accountHolderName && `%${filter.accountHolderName}%`,
    },
    {
      op: "EQ",
      field: "iban",
      value: filter.iban,
    },
    {
      op: "EQ",
      field: "amountMinorUnits",
      value: filter.amount && !isNaN(+filter.amount) && +filter.amount * 100,
    },
    {
      op: "EQ",
      field: filter.paymentType === "unidentified" ? "paymentType" : "type",
      value: filter.paymentMethod,
    },
    {
      op: "GT",
      field: filter.paymentType === "successful" ? "createdOn" : "uploadDate",
      value: formatISODateTime(filter.uploadDate?.[0]),
    },
    {
      op: "LT",
      field: filter.paymentType === "successful" ? "createdOn" : "uploadDate",
      value: addOneDayAndFormatISO(filter.uploadDate?.[1]),
    },
    {
      op: "GT",
      field: "accountingDate",
      value: formatISODateTime(filter.accountingDate?.[0]),
    },
    {
      op: "LT",
      field: "accountingDate",
      value: addOneDayAndFormatISO(filter.accountingDate?.[1]),
    },
  ].filter((f) => f.value !== "" && f.value !== null && f.value !== undefined);

  const variables = {
    search: {
      filter:
        filters.length > 1
          ? {
              op: "and",
              value: filters,
            }
          : filters[0] ?? null,
      sorts: [{ property: "createdOn", direction: "DESC" }],
      page: page - 1, // antd pagination starts with 1, but backend - with 0
      pageSize,
    },
  };

  if (!filter.paymentType) {
    throw new Error("Payment type is not specified");
  }
  const { query, domain } = configByPaymentType[filter.paymentType];
  const { data, errors } = await callGql({
    query: query,
    variables,
    domain: domain,
  });

  if (errors.length) {
    throw errors[0].message;
  }

  if ("searchRetailPayments" in data) {
    const orderIds = getOrderIds(data);
    const orderNumbersMap = await fetchOrderNumbers(orderIds);

    return {
      searchRetailPayments: {
        ...data.searchRetailPayments,
        entities: orderNumbersAdapter(
          data.searchRetailPayments.entities,
          orderNumbersMap
        ),
      },
    };
  }

  return data;
};
