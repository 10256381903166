import { useMemo, useContext } from "react";

import {
  EditSuccessfulPermission,
  ViewPermission,
  EditUnidentifiedPermission,
} from "./../constants/index";
import { PermissionsContext } from "../providers/PermissionsProvider";

export type Permission =
  | EditSuccessfulPermission
  | EditUnidentifiedPermission
  | ViewPermission;

const hasRequiredPermissions =
  (availablePermissions: Permission[]) => (types: Permission | Permission[]) =>
    [types].flat().some((type) => availablePermissions.includes(type));

export const useHasPermissions = () => {
  const { permissions } = useContext(PermissionsContext);

  return useMemo(() => hasRequiredPermissions(permissions), [permissions]);
};

export default useHasPermissions;
