import React, { useState, useEffect, FC } from "react";

import { callGql } from "../utils/apiClient";
import { permissionsQuery } from "../graphql";
import { Permission } from "../hooks";

export const PermissionsContext = React.createContext({
  permissions: [] as Permission[],
});

export const PermissionsProvider: FC = ({ children }) => {
  const [permissions, setPermissions] = useState<Array<Permission>>();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await callGql({
        query: permissionsQuery,
      });

      setPermissions(data.me.effectivePermissions);
    };

    fetchData();
  }, []);

  if (!permissions) return null;

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};
