import { routePathByPage } from "../routes";
import { PaymentOverviewCard } from "../components";
import { useCurrentPaymentType, usePaymentDetails } from "./hooks";
import { PaymentHeader } from "./PaymentHeader";
import { PaymentType } from "./types";
import { SuccessfulPaymentOverview } from "./SuccessfulPaymentOverview";
import { SuccessfulPayment } from "../adapters";
import { useNavigateToPaymentDetails } from "./useNavigateToPaymentDetails";

const PaymentOverview = () => {
  const paymentType = useCurrentPaymentType();
  const { data } = usePaymentDetails(paymentType);

  const navigateTo = useNavigateToPaymentDetails(routePathByPage.details);

  return (
    <>
      <PaymentHeader
        title={`Payment #${data?.externalId ?? ""}`}
        onNavigate={navigateTo}
      />
      {paymentType === PaymentType.Successful ? (
        <SuccessfulPaymentOverview payment={data as SuccessfulPayment} />
      ) : (
        <PaymentOverviewCard mode="view" payment={data} />
      )}
    </>
  );
};

export default PaymentOverview;
