import { Button, Form, Space } from "antd";

import { useHasPermissions } from "../hooks";
import { PaymentOverviewCard } from "../components";
import { PaymentField } from "../components/PaymentOverviewCard";
import { EditSuccessfulPermission } from "../constants";
import { SuccessfulPayment } from "../adapters";
import { useUpdateSuccessfulPayment } from "./useUpdateSuccessfulPayment";
import { PaymentMethod } from "./types";

const canUpdateSuccessfulPaymentMethod = (method?: PaymentMethod) =>
  method === "WIRE_TRANSFER_CUSTOMER" ||
  method === "WIRE_TRANSFER_BANK_FINANCE";

export const SuccessfulPaymentOverview = ({
  payment,
}: {
  payment: SuccessfulPayment | undefined;
}) => {
  const hasPermissions = useHasPermissions();
  const canUpdateSuccessfulPayment = hasPermissions(
    EditSuccessfulPermission.updateSuccessfulPayment
  );

  const [form] = Form.useForm<SuccessfulPayment>();
  const updatePaymentMutation = useUpdateSuccessfulPayment(payment?.id);
  const editableFields = [
    canUpdateSuccessfulPaymentMethod(payment?.paymentType)
      ? "paymentType"
      : null,
  ].filter(Boolean) as PaymentField[];

  return (
    <>
      {canUpdateSuccessfulPayment && payment ? (
        <Form
          form={form}
          initialValues={payment}
          onFinish={updatePaymentMutation.mutate}
        >
          <PaymentOverviewCard
            mode="edit"
            editableFields={editableFields}
            payment={payment}
          />
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={updatePaymentMutation.isLoading}
              loading={updatePaymentMutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Form>
      ) : (
        <PaymentOverviewCard mode="view" payment={payment} />
      )}
    </>
  );
};
