import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { OrderSearchFormVM } from "../../components/OrderMatchesSeachBar";
import { useNavigateToPaymentDetails } from "../../pages/useNavigateToPaymentDetails";
import { routePathByPage } from "../../routes";
import { OrderMatchesTable } from "../../components";
import { useQueryParams } from "../../hooks";
import {
  SUGGESTION_COLUMNS,
  SEARCH_COLUMNS,
} from "../../components/OrderMatchesTable/config";
import { useOrders, useSuggestions, usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";
import { PaymentType } from "../types";
import { UnidentifiedPaymentEditForm } from "./UnidentifiedPaymentEditForm";

export const UnidentifiedPaymentEdit = () => {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);
  const { data: suggestions, refetch: searchSuggestions } =
    useSuggestions(payment);
  const [queryParams, setQueryParams] = useQueryParams();
  const { orderId } = queryParams;
  const { data: orders = [] } = useOrders(orderId);
  useEffect(() => {
    if (payment) {
      searchSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const navigateTo = useNavigateToPaymentDetails(
    routePathByPage.editUnidentified
  );

  const prevState = useHistory().location.state;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orderId, ...restQueryParams } = queryParams;
    setQueryParams(restQueryParams, prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrdersSearch = (values: OrderSearchFormVM) =>
    setQueryParams(
      {
        ...queryParams,
        ...values,
      },
      prevState
    );

  return (
    <>
      <PaymentHeader
        title={`Payment #${payment?.externalId ?? ""}`}
        onNavigate={navigateTo}
      />
      {payment ? <UnidentifiedPaymentEditForm payment={payment} /> : null}
      <OrderMatchesTable
        columns={SUGGESTION_COLUMNS}
        data={suggestions ?? []}
        title="Matching suggestions"
      />
      <OrderMatchesTable
        columns={SEARCH_COLUMNS}
        data={orders ?? []}
        title="Search orders"
        onSearch={handleOrdersSearch}
      />
    </>
  );
};
