import { useQuery } from "react-query";

import { PAGE_SIZE, domains } from "../../constants";
import { searchOrdersQuery } from "../../graphql";
import { searchOrdersAdapter } from "../../adapters";
import { callGql } from "../../utils/apiClient";
import { buildSearchOrdersFilter, SearchProps } from "../../utils/searchOrders";

export const fetchOrders = async (search: SearchProps) => {
  const filter = buildSearchOrdersFilter(search);

  if (!filter) return;

  const variables = {
    search: {
      filter,
      page: 0,
      pageSize: PAGE_SIZE,
      sorts: [
        {
          property: "updated",
          direction: "DESC",
        },
      ],
    },
  };

  const { data, errors } = await callGql({
    query: searchOrdersQuery,
    variables,
    domain: domains.ORDER,
  });

  if (errors.length) {
    throw errors[0].exception?.localizedMessage;
  }

  return searchOrdersAdapter(data.searchOrders);
};

export const useOrders = (orderNumber: string | undefined) => {
  const searchOrders = async () => {
    if (orderNumber) {
      return fetchOrders({
        orderNumbers: [orderNumber],
      });
    }
  };

  return useQuery(["orders", orderNumber], searchOrders, {
    enabled: Boolean(orderNumber),
  });
};
