import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";

import { useGoBackToOverviewPage } from "./../../useGoBackToOverviewPage";
import { OtherPaymentType } from "../../../adapters";
import { markUnidentifiedPaymentAsOther } from "./markUnidentifiedPaymentAsOther";

export const useMarkUnidentifiedPaymentAsOther = (paymentId: string) => {
  const queryClient = useQueryClient();
  const goBackToOverviewPage = useGoBackToOverviewPage();
  return useMutation(async (category: OtherPaymentType) => {
    await markUnidentifiedPaymentAsOther(paymentId, category);
    queryClient.refetchQueries(["paymentDetails", paymentId]);
    queryClient.refetchQueries(["payments"]);
    goBackToOverviewPage();

    message.success("Success");
  });
};
