import { SuccessfulPaymentResponse } from "./../pages/hooks/usePaymentDetails/constants";
import { formatAmount } from "../utils/amount";
import { formatDate } from "../utils/date";
import { formatSource } from "./formatSource";
import { PaymentUploadStatus } from "../constants";
import { OrderDetails, PaymentBase } from "./types";

export type SuccessfulPayment = PaymentBase &
  OrderDetails & { id: string; uploadStatus: PaymentUploadStatus };

export const successfulPaymentAdapter = ({
  retailPayment: data,
  order: orderData,
}: SuccessfulPaymentResponse): SuccessfulPayment => ({
  id: data.id,
  createdOn: data.createdOn ? formatDate(data.createdOn, true) : "",
  accountingDate: data.accountingDate ? formatDate(data.accountingDate) : "",
  source: formatSource({
    creationSource: data.creationSource,
    hasExternalPaymentSystem: !!data.externalPaymentSystem,
  }),
  recipientBank: data.importDetails?.recipientBankAccount ?? "",
  customerReference: data.importDetails?.customerPaymentReference ?? "",
  paymentType: data.type,
  uploadStatus: PaymentUploadStatus.SUCCESSFUL,
  errorMessage: "",
  amount: formatAmount(
    data.amount.amountMinorUnits,
    data.amount.currencyCode,
    true
  ),
  accountHolderName: data.accountDetails?.holder,
  customerName: orderData.customer
    ? `${orderData?.customer?.firstName || ""} ${
        orderData?.customer?.lastName || ""
      }`
    : "-",
  bic: data.accountDetails?.bic,
  iban: data.accountDetails?.iban,
  orderId: orderData?.orderNumber,
  stockId: orderData?.stockNumber,
  retailCountry: data.countryCode,
  externalId: data.externalId,
});
