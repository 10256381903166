import { useQuery } from "react-query";

import { UnidentifiedPayment } from "../../adapters";
import { fetchOrders } from "./useOrders";

export const useSuggestions = (payment?: UnidentifiedPayment) => {
  const searchSuggestions = async () => {
    if (payment) {
      return fetchOrders({
        orderNumbers: payment.orderNumbers,
      });
    }
  };

  return useQuery(["suggestions", payment], searchSuggestions, {
    refetchOnMount: false,
  });
};
