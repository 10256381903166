import { useQuery } from "react-query";

import { InboundsFilter } from "../../PaymentsOverviewFilter";
import { fetchPayments } from "../../fetchPayments/fetchPayments";
import {
  otherPaymentsResponseToPaymentsAdapter,
  successfulPaymentsResponseToPaymentsAdapter,
  unidentifiedPaymentsResponseToPaymentsAdapter,
} from "./adapters";
import {
  FetchSuccessfulPaymentsResponse,
  FetchUnidentifiedPaymentsResponse,
} from "../../fetchPayments";

const isSuccessfulPaymentsResponse = (
  r: unknown
): r is FetchSuccessfulPaymentsResponse =>
  typeof r === "object" && !!r && "searchRetailPayments" in r;

const isUnidentifiedPaymentsResponse = (
  r: unknown
): r is FetchUnidentifiedPaymentsResponse =>
  typeof r === "object" && !!r && "searchRetailUnidentifiedPayments" in r;

export const usePayments = (filter: InboundsFilter, page: number) => {
  const fetch = async () => {
    const response = await fetchPayments(filter, page);
    if (isSuccessfulPaymentsResponse(response)) {
      return successfulPaymentsResponseToPaymentsAdapter(response);
    } else if (isUnidentifiedPaymentsResponse(response)) {
      return unidentifiedPaymentsResponseToPaymentsAdapter(response);
    }
    return otherPaymentsResponseToPaymentsAdapter(response);
  };

  return useQuery(["payments", page, filter], fetch, {
    refetchOnMount: false,
    enabled: Boolean(filter?.paymentType),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
