import { otherPaymentTypeByLabel } from "../../../constants";
import { PaymentDistribution } from "./parsePaymentDistributionInput";
import { PaymentDistributionEntity } from "./useDistributeUnidentifiedPayment";
import { isCategory } from "./validatePaymentDistribution/validateCategories";

export const adaptToPaymentDistributionEntity = ({
  amountMinorUnits,
  orderNumberOrCategory,
}: PaymentDistribution): PaymentDistributionEntity => ({
  amountMinorUnits,
  ...(isCategory(orderNumberOrCategory)
    ? {
        category: otherPaymentTypeByLabel[orderNumberOrCategory.toLowerCase()],
      }
    : {
        orderNumber: orderNumberOrCategory,
      }),
});
