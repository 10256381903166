import { FetchOtherPaymentsResponse } from "./../../../fetchPayments/types";
import {
  formatDate,
  formatAmount,
  formatPaymentMethod,
} from "../../../../../utils";
import { IPaymentsOverview } from "../types";

export const otherPaymentsResponseToPaymentsAdapter = ({
  searchInternalTransferPayments: data,
}: FetchOtherPaymentsResponse): IPaymentsOverview => {
  const { entities, totalEntityCount } = data;

  const parsedEntities = entities.map((entity) => ({
    id: entity.id,
    uploadTimestamp: formatDate(entity.createdOn, true),
    accountingDate: formatDate(entity.accountingDate),
    paymentMethod: formatPaymentMethod(entity.type),
    externalId: entity.externalId,
    amount: formatAmount(
      entity.amount.amountMinorUnits,
      entity.amount.currencyCode,
      true
    ),
    accountHolder: entity.accountData?.holder,
    orderId: "",
    orderNumber: "",
    country: entity.countryCode,
    action: {
      paymentId: entity.id,
      externalId: entity.externalId,
    },
  }));

  return {
    entities: parsedEntities,
    totalEntityCount,
  };
};
