import { Key, useState } from "react";
import { Button, Form, Modal, Select, Typography } from "antd";

import { useTransitionToInternal } from "./useTransitionToInternal";
import { OtherPaymentType } from "../../adapters";
import { useOtherPaymentCategoryOptions } from "../../pages/hooks";
const { Text } = Typography;

export const MarkAsOther = ({
  selectedRowKeys,
  clearSelectedRowKeys,
}: {
  selectedRowKeys: Key[];
  clearSelectedRowKeys: () => void;
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<OtherPaymentType>();
  const otherPaymentCategoryOptions = useOtherPaymentCategoryOptions();

  const handleCancel = () => setOpened(false);

  const { isLoading, mutate } = useTransitionToInternal({
    onSuccess: () => {
      handleCancel();
      clearSelectedRowKeys();
    },
    selectedRowKeys: selectedRowKeys,
    selectedCategory: selectedCategory,
  });

  return (
    <>
      <Button
        disabled={selectedRowKeys.length === 0}
        onClick={() => setOpened(true)}
      >
        Mark As Other
        {selectedRowKeys.length ? <> ({selectedRowKeys.length})</> : ""}
      </Button>

      <Modal
        title="Mark As Other"
        visible={opened}
        onCancel={handleCancel}
        okButtonProps={{ loading: isLoading, disabled: !selectedCategory }}
        onOk={() => {
          mutate();
        }}
      >
        <p>
          Mark {selectedRowKeys.length} row
          {selectedRowKeys.length > 1 ? "s" : ""} as other payment.{" "}
        </p>

        <Form layout="vertical">
          <Form.Item required label={<Text>Category</Text>} name="country">
            <Select<OtherPaymentType>
              style={{ width: "100%" }}
              size="large"
              placeholder="Select category"
              onSelect={setSelectedCategory}
              options={otherPaymentCategoryOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
