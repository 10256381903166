import { Space, Typography } from "antd";
import { Key, useState } from "react";

import { Pagination, TotalResults } from "../../components";
import { useQueryParams } from "../../hooks";
import { DEFAULT_PAGE, PAGE_SIZE } from "../../constants";
import { usePayments } from "./hooks";
import {
  PaymentsOverviewFilter,
  useInboundsFilter,
} from "./PaymentsOverviewFilter";
import PaymentsOverviewNavMenu from "./PaymentsOverviewNavMenu";
import PaymentsOverviewTable from "./PaymentsOverviewTable";

export const PaymentsOverview = () => {
  const [queryParams, setQueryParams] = useQueryParams();
  const { page = DEFAULT_PAGE } = queryParams;
  const [filter] = useInboundsFilter();

  const { data, isFetching } = usePayments(filter, Number(page));

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const total = data?.totalEntityCount ?? 0;

  const handlePageChange = (page: number) =>
    setQueryParams({ ...queryParams, page: page.toString() });

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Typography.Title>Inbound Payments - Overview</Typography.Title>
      <PaymentsOverviewNavMenu />

      <PaymentsOverviewFilter
        total={total}
        page={Number(page)}
        selectedRowKeys={selectedRowKeys}
        clearSelectedRowKeys={() => setSelectedRowKeys([])}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TotalResults totalCount={total} />
        <Pagination
          totalCount={total}
          pageSize={PAGE_SIZE}
          page={Number(page)}
          onChange={handlePageChange}
        />
      </div>
      <PaymentsOverviewTable
        loading={isFetching}
        data={data?.entities || []}
        page={page}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          totalCount={total}
          pageSize={PAGE_SIZE}
          page={Number(page)}
          onChange={handlePageChange}
        />
      </div>
    </Space>
  );
};
