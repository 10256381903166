export const searchOrdersQuery = `query($search: SearchProjection2Input!) {
  searchOrders(search: $search) {
    entities {
      customer {
        firstName
        lastName
        name
      }
      customerCountry
      id
      orderNumber
      paymentDetails {
        paidAmount {
          amountMinorUnits
          currencyCode
        }
        remainingAmount {
          amountMinorUnits
          currencyCode
        }
      }
      retailCountry
      state
      stockNumber
    }
  }
}`;
