import { ComponentType } from "react";

import PaymentsOverview from "./pages/PaymentsOverview";
import PaymentOverview from "./pages/PaymentOverview";
import { UnidentifiedPaymentEdit } from "./pages/UnidentifiedPaymentEdit";
import { Permission } from "./hooks";
import { EditUnidentifiedPermission, ViewPermission } from "./constants";
import { PaymentType } from "./pages/types";

interface Route {
  path: string;
  exact: boolean;
  component: ComponentType;
  permissions: Permission[];
}

export const routePathByPage = {
  list: `/:lang/payments/:paymentType(${PaymentType.Successful}|${PaymentType.Internal}|${PaymentType.Unidentified})`,
  details: `/:lang/payments/:paymentType(${PaymentType.Successful}|${PaymentType.Internal}|${PaymentType.Unidentified})/:id`,
  editUnidentified: `/:lang/payments/:paymentType(${PaymentType.Unidentified})/:id/edit`,
} as const;

const routes: Array<Route> = [
  {
    path: routePathByPage.list,
    exact: true,
    permissions: [
      ViewPermission.queryOtherPayments,
      ViewPermission.queryRetailPayments,
      ViewPermission.queryUnidentifiedPayments,
    ],
    component: PaymentsOverview,
  },
  {
    path: routePathByPage.details,
    exact: true,
    permissions: [
      ViewPermission.queryOtherPayment,
      ViewPermission.queryRetailPayment,
      ViewPermission.queryUnidentifiedPayment,
    ],
    component: PaymentOverview,
  },
  {
    path: routePathByPage.editUnidentified,
    exact: true,
    permissions: Object.values(EditUnidentifiedPermission),
    component: UnidentifiedPaymentEdit,
  },
];

export default routes;
