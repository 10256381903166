import { PaymentType } from "../../../pages/types";
import { domains } from "../../../constants";
import {
  otherPaymentsQuery,
  unidentifiedPaymentsQuery,
  successfulPaymentsQuery,
} from "./queries";

type Config = {
  query: string;
  domain: domains;
};

export const configByPaymentType: Record<PaymentType, Config> = {
  successful: {
    query: successfulPaymentsQuery,
    domain: domains.ORDER,
  },
  unidentified: {
    query: unidentifiedPaymentsQuery,
    domain: domains.PAYMENTS,
  },
  internal: {
    query: otherPaymentsQuery,
    domain: domains.PAYMENTS,
  },
};
