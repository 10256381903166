import { fetchPayments } from "../fetchPayments";
import { InboundsFilter } from "../PaymentsOverviewFilter";
import {
  otherPaymentsResponseToCsvRowsAdapter,
  successfulPaymentsResponseToCsvRowsAdapter,
  unidentifiedPaymentsResponseToCsvRowsAdapter,
} from "./adapters";

export const fetchPaymentsForCsv =
  (filter: InboundsFilter, total: number, page = 1) =>
  async () => {
    const response = await fetchPayments(filter, page, total);
    if ("searchRetailUnidentifiedPayments" in response) {
      return unidentifiedPaymentsResponseToCsvRowsAdapter(response);
    } else if ("searchRetailPayments" in response) {
      return successfulPaymentsResponseToCsvRowsAdapter(response);
    } else {
      return otherPaymentsResponseToCsvRowsAdapter(response);
    }
  };
