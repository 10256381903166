import { formatAmount } from "../../../utils";
import { UnidentifiedPayment } from "../../../adapters";
import { PaymentDistribution } from "./parsePaymentDistributionInput";

export const adaptToPaymentDistributionTableRow =
  (payment: UnidentifiedPayment) =>
  (
    { amountMinorUnits, orderNumberOrCategory }: PaymentDistribution,
    idx: number
  ) => ({
    accountingDate: payment?.accountingDate,
    paymentMethod: payment?.paymentType,
    externalId: `${payment?.externalId}_${idx + 1}`,
    amount: formatAmount(
      amountMinorUnits ?? 0,
      payment?.currencyCode ?? "",
      true
    ),
    orderNumberOrCategory,
  });
