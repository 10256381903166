import { PageHeader } from "antd";

import { PaymentDetailsNavigation } from "./PaymentDetailsNavigation";
import { PaymentOverviewEntity } from "./PaymentsOverview/hooks";
import { useGoBackToOverviewPage } from "./useGoBackToOverviewPage";

type PaymentHeaderProps = {
  title: string;
  onNavigate: (payment: PaymentOverviewEntity, page: number) => void;
};

export const PaymentHeader = ({ title, onNavigate }: PaymentHeaderProps) => {
  const goBackToOverviewPage = useGoBackToOverviewPage();

  return (
    <PageHeader
      onBack={goBackToOverviewPage}
      title={title}
      extra={
        <PaymentDetailsNavigation
          onSearchMoreClick={goBackToOverviewPage}
          onNavigate={onNavigate}
        />
      }
    />
  );
};
