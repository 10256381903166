import { orderNumbersQuery } from "./orderNumbersQuery";
import { callGql } from "../../../../utils/apiClient";
import { domains, PAGE_SIZE } from "../../../../constants";

export const fetchOrderNumbers = async (
  orderIds: string[]
): Promise<Record<string, string>> => {
  if (orderIds.length === 0) return {};

  const variables = {
    search: {
      filter: {
        field: "id",
        op: "in",
        value: orderIds,
      },
      sorts: [{ property: "created", direction: "DESC" }],
      page: 0,
      pageSize: PAGE_SIZE,
    },
  };

  const {
    data: { searchOrders },
    errors,
  } = await callGql({
    query: orderNumbersQuery,
    variables,
    domain: domains.ORDER,
  });

  if (errors.length) {
    throw errors[0].exception?.localizedMessage;
  }

  // TODO
  return searchOrders.entities.reduce(
    (acc: Record<string, string>, { id, orderNumber }: any) => {
      acc[id] = orderNumber;

      return acc;
    },
    {} as Record<string, string>
  );
};
