import { domains } from "../../../constants";
import { attachOrderMutation } from "../../../graphql";
import { callGql } from "../../../utils/apiClient";

export const assignUnidentifiedPaymentToOrder = async (
  paymentId: string,
  orderId: string
) => {
  const variables = {
    unidentifiedPaymentId: paymentId,
    orderId,
  };

  const {
    data: { assignUnidentifiedPaymentToOrder },
    errors,
  } = await callGql({
    query: attachOrderMutation,
    variables,
    domain: domains.PAYMENTS,
  });

  if (errors.length) {
    throw errors[0].message;
  }
  return assignUnidentifiedPaymentToOrder;
};
