import { Button, Modal, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { UnidentifiedPayment } from "../../../adapters";
import { fetchOrders } from "../../../pages/hooks/useOrders";
import { useGoBackToOverviewPage } from "../../../pages/useGoBackToOverviewPage";
import { formatAmount } from "../../../utils";
import {
  PaymentDistribution,
  parsePaymentDistributionInput,
} from "./parsePaymentDistributionInput";
import { AttributionsTable } from "./AttributionsTable";
import { adaptToPaymentDistributionTableRow } from "./adaptToPaymentDistributionTableRow";
import { validatePaymentDistribution } from "./validatePaymentDistribution";
import { DistributePaymentForm } from "./DistributePaymentForm";
import { useDistributeUnidentifiedPayment } from "./useDistributeUnidentifiedPayment";
import { adaptToPaymentDistributionEntity } from "./adaptToPaymentDistributionEntity";
import { isCategory } from "./validatePaymentDistribution/validateCategories";

type DistributePaymentModalProps = {
  payment: UnidentifiedPayment | undefined;
  onClose: () => void;
  visible: boolean;
};

export const DistributePaymentModal = ({
  payment,
  onClose,
  visible,
}: DistributePaymentModalProps) => {
  const [errorByDistributionField, setErrorByDistributionField] = useState({
    category: "",
    orderNumbers: "",
    amount: "",
    unknownFields: "",
  });

  const [parsedRows, setParsedRows] = useState<Array<PaymentDistribution>>([]);

  const parsedOrderNumbers = useMemo(
    () =>
      parsedRows
        .map((row) => row.orderNumberOrCategory)
        .filter((orderNumberOrCategory) => !isCategory(orderNumberOrCategory)),
    [parsedRows]
  );

  const searchOrdersQuery = useQuery(
    ["orders", parsedOrderNumbers],
    () => fetchOrders({ orderNumbers: parsedOrderNumbers }),
    {
      refetchOnReconnect: false,
      enabled: parsedOrderNumbers.length > 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!searchOrdersQuery.isLoading) {
      const existingOrderNumbers = (searchOrdersQuery.data ?? []).map(
        (order) => order.orderNumber
      );
      setErrorByDistributionField(
        validatePaymentDistribution(parsedRows, existingOrderNumbers)
      );
    }
  }, [parsedRows, searchOrdersQuery.data, searchOrdersQuery.isLoading]);

  const checkPaymentDistributionInput = (values: {
    paymentDistribution: string | undefined;
  }) => {
    const paymentDistribution = values?.paymentDistribution?.trim();

    if (!paymentDistribution) return;

    const parsedRows = parsePaymentDistributionInput(paymentDistribution);
    setParsedRows(parsedRows);
  };

  const errors = Object.values(errorByDistributionField).filter(
    Boolean
  ) as string[];

  const shouldShowTable =
    !searchOrdersQuery.isLoading && errors.length === 0 && parsedRows.length;

  const tableData = payment
    ? parsedRows.map(adaptToPaymentDistributionTableRow(payment))
    : [];

  const enteredTotalAmountMinorUnits = parsedRows.reduce(
    (prev, curr) => prev + curr.amountMinorUnits,
    0
  );

  const isValidPaymentDistributions =
    enteredTotalAmountMinorUnits === payment?.amountMinorUnits &&
    errors.length === 0;
  const canDistributePayment =
    isValidPaymentDistributions &&
    tableData.length > 0 &&
    !searchOrdersQuery.isLoading;

  const distributePaymentMutation = useDistributeUnidentifiedPayment();

  const goBackToOverviewPage = useGoBackToOverviewPage();
  const handleSubmit = async () => {
    if (payment) {
      await distributePaymentMutation.mutateAsync({
        paymentId: payment.id,
        paymentDistribution: parsedRows.map(adaptToPaymentDistributionEntity),
      });
      goBackToOverviewPage();
    }
  };

  const handleClose = () => {
    onClose();
    setParsedRows([]);
    setErrorByDistributionField({
      category: "",
      orderNumbers: "",
      amount: "",
      unknownFields: "",
    });
  };

  const formattedEnteredAmount = payment
    ? formatAmount(enteredTotalAmountMinorUnits, payment.currencyCode, true)
    : "";

  const formattedPaymentAmount = payment
    ? formatAmount(payment.amountMinorUnits, payment.currencyCode, true)
    : "";

  const isValidEnteredAmount =
    formattedPaymentAmount === formattedEnteredAmount;

  return (
    <Modal
      title="Distribute payment"
      width={800}
      destroyOnClose
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button disabled={searchOrdersQuery.isLoading} onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={distributePaymentMutation.isLoading}
          htmlType="submit"
          disabled={
            !canDistributePayment || distributePaymentMutation.isLoading
          }
        >
          Submit
        </Button>,
      ]}
    >
      <>
        <DistributePaymentForm
          errors={errors}
          isLoading={searchOrdersQuery.isLoading}
          onSubmit={checkPaymentDistributionInput}
        />
        {shouldShowTable ? (
          <>
            <AttributionsTable data={tableData} />
            <Typography.Paragraph
              style={{
                marginTop: 8,
                color: isValidEnteredAmount ? undefined : "#ff4d4f",
              }}
            >
              {isValidEnteredAmount
                ? `Total amount: ${formattedPaymentAmount}`
                : `Total amount (${formattedEnteredAmount}) is not equal to the corresponding unidentified payment amount (${formattedPaymentAmount})`}
            </Typography.Paragraph>
          </>
        ) : null}
      </>
    </Modal>
  );
};
