import { OtherPaymentType } from "../../../adapters";
import { domains } from "../../../constants";
import { markUnidentifiedPaymentAsOtherMutation } from "../../../graphql";
import { callGql } from "../../../utils/apiClient";

export const markUnidentifiedPaymentAsOther = async (
  id: string,
  category: OtherPaymentType
) => {
  const variables = { id, category };

  const {
    data: { transitionPaymentUnidentifiedToInternalTransfer },
    errors,
  } = await callGql({
    query: markUnidentifiedPaymentAsOtherMutation,
    variables,
    domain: domains.PAYMENTS,
  });

  if (errors.length) {
    throw errors[0];
  }
  return transitionPaymentUnidentifiedToInternalTransfer;
};
