import { CreationSource } from "../adapters/formatSource";
import { OtherPaymentType } from "../adapters";
import {
  methodsMap,
  unidentifiedPaymentMethodsMap,
} from "../utils/paymentMethod";

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 50;

export const ERROR_MESSAGE = "Oops! Something went wrong.";

export enum PaymentUploadStatus {
  SUCCESSFUL = "Successful",
  UNIDENTIFIED = "Unidentified",
}

export enum domains {
  ORDER = "order.management",
  PAYMENTS = "payments.import.management",
}

export enum EditUnidentifiedPermission {
  assignUnidentifiedPaymentToOrder = "gqls://payments.import.management/mutation/assignunidentifiedpaymenttoorder",
  markUnidentifiedPaymentAsOther = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransfer",
  updateUnidentifiedPayment = "gqls://payments.import.management/mutation/updateretailunidentifiedpayment",
  changeRetailPaymentOrder = "gqls://payments.import.management/mutation/changeretailpaymentorder",
  transitionPaymentUnidentifiedToInternalTransferBatch = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransferbatch",
  distributeUnidentifiedPayment = "gqls://payments.import.management/mutation/distributeunidentifiedpayment",
}

export enum EditSuccessfulPermission {
  updateSuccessfulPayment = "gqls://payments.import.management/mutation/updateretailpayment",
}

export enum ViewPermission {
  queryRetailPayment = "gqls://order.management/query/retailpayment",
  queryRetailPayments = "gqls://order.management/query/searchretailpayments",
  queryOtherPayment = "gqls://payments.import.management/query/internaltransferpaymentbyid",
  queryOtherPayments = "gqls://payments.import.management/query/searchinternaltransferpayments",
  queryUnidentifiedPayment = "gqls://payments.import.management/query/unidentifiedpaymentbyid",
  queryUnidentifiedPayments = "gqls://payments.import.management/query/searchretailunidentifiedpayments",
}

export const paymentMethodsOptions = Object.entries(methodsMap).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const creationSourceOptions = Object.entries(CreationSource).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const unidentifiedPaymentMethodsOptions = Object.entries(
  unidentifiedPaymentMethodsMap
).map(([value, label]) => ({
  value,
  label,
}));

export const labelByOtherPaymentType: Record<OtherPaymentType, string> = {
  [OtherPaymentType.Chargeback]: "Chargeback",
  [OtherPaymentType.Internal]: "Internal",
  [OtherPaymentType.Legal]: "Legal",
  [OtherPaymentType.Logistic]: "Logistic",
  [OtherPaymentType.NotApplicable]: "Not applicable payment",
  [OtherPaymentType.OnHold]: "On hold",
  [OtherPaymentType.Overpayment]: "Overpayment",
  [OtherPaymentType.RatenkaufFinancing]: "Ratenkauf financing",
  [OtherPaymentType.RejectedOutbound]: "Rejected outbound payment",
  [OtherPaymentType.Refund]: "Refund",
  [OtherPaymentType.OutgoingRefund]: "Outgoing Refund",
  [OtherPaymentType.DuplicateFinancingAmount]: "Duplicate Financing Amount",
  [OtherPaymentType.FinancingKickback]: "Financing Kickback",
  [OtherPaymentType.PenaltyFees]: "Penalty Fees",
  [OtherPaymentType.ChequePaymentsBatch]: "Cheque Payments Batch",
};

export const otherPaymentTypeByLabel: Record<string, OtherPaymentType> = {
  chargeback: OtherPaymentType.Chargeback,
  internal: OtherPaymentType.Internal,
  legal: OtherPaymentType.Legal,
  logistic: OtherPaymentType.Logistic,
  "not applicable payment": OtherPaymentType.NotApplicable,
  "on hold": OtherPaymentType.OnHold,
  overpayment: OtherPaymentType.Overpayment,
  "ratenkauf financing": OtherPaymentType.RatenkaufFinancing,
  "rejected outbound payment": OtherPaymentType.RejectedOutbound,
  refund: OtherPaymentType.Refund,
  "outgoing refund": OtherPaymentType.OutgoingRefund,
  "duplicate financing amount": OtherPaymentType.DuplicateFinancingAmount,
  "financing kickback": OtherPaymentType.FinancingKickback,
  "cheque payments batch": OtherPaymentType.ChequePaymentsBatch,
  "penalty fees": OtherPaymentType.PenaltyFees,
};
