import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";

import { domains } from "../../../constants";
import { distributeUnidentifiedPayment } from "../../../graphql/mutations/distributeUnidentifiedPayment";
import { callGql } from "../../../utils/apiClient";

export type PaymentDistributionEntity =
  | {
      amountMinorUnits: number;
      orderNumber: string;
    }
  | {
      amountMinorUnits: number;
      category: string;
    };

export const useDistributeUnidentifiedPayment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      paymentId,
      paymentDistribution,
    }: {
      paymentId: string;
      paymentDistribution: PaymentDistributionEntity[];
    }) => {
      const { errors } = await callGql({
        query: distributeUnidentifiedPayment,
        variables: {
          distribution: {
            unidentifiedPaymentId: paymentId,
            items: paymentDistribution,
          },
        },
        domain: domains.PAYMENTS,
      });

      if (errors.length) {
        throw errors[0]?.message;
      }
      message.success("Payment was successfully distributed");
      queryClient.refetchQueries(["payments"]);
    }
  );
};
