import { useMutation } from "react-query";
import { message } from "antd";
import { Key } from "react";

import { transitionPaymentUnidentifiedToInternalTransferBatch } from "./transitionPaymentUnidentifiedToInternalTransferBatch";
import { callGql } from "../../utils/apiClient";
import { DEFAULT_PAGE, domains } from "../../constants";
import { OtherPaymentType } from "../../adapters";
import { usePayments } from "../../pages/PaymentsOverview/hooks";
import { useQueryParams } from "../../hooks";
import { useInboundsFilter } from "../../pages/PaymentsOverview/PaymentsOverviewFilter";

export const useTransitionToInternal = ({
  onSuccess,
  selectedRowKeys,
  selectedCategory,
}: {
  onSuccess: () => void;
  selectedRowKeys: Key[];
  selectedCategory?: OtherPaymentType;
}) => {
  const [queryParams] = useQueryParams();
  const { page = DEFAULT_PAGE } = queryParams;
  const [filter] = useInboundsFilter();
  const { refetch } = usePayments(filter, Number(page));

  return useMutation({
    onSuccess,
    mutationFn: async () => {
      const { data, errors } = await callGql({
        query: transitionPaymentUnidentifiedToInternalTransferBatch,
        variables: {
          request: {
            entities: selectedRowKeys.map((id) => ({
              id,
              category: selectedCategory,
            })),
          },
        },
        domain: domains.PAYMENTS,
      });
      if (errors?.length) {
        throw errors[0]?.message;
      }
      await refetch();
      message.success("Payments updated successfully");
      return data;
    },
  });
};
