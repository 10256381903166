import { Card, Select, InputNumber, Input, Space, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Key, useEffect } from "react";
import { useQuery } from "react-query";

import { DateRangePicker, ExportToCsvButton } from "../../../components";
import { InboundsFilter, useInboundsFilter } from "./useInboundsFilter";
import {
  creationSourceOptions,
  EditUnidentifiedPermission,
  PAGE_SIZE,
  paymentMethodsOptions,
} from "../../../constants";
import { filterOption, dateFormat } from "../../../utils";
import { useOtherPaymentCategoryOptions } from "../../hooks";
import { fetchPaymentsForCsv } from "../fetchPaymentsForCsv";
import { PaymentType } from "../../types";
import { MarkAsOther } from "../../../components/MarkAsOther";
import { useHasPermissions } from "../../../hooks";

const minWidth = {
  minWidth: 200,
};
export enum UnidentifiedPaymentStatus {
  Open = "OPEN",
  OnHold = "ON_HOLD",
}
export const labelByUnidentifiedPaymentStatusOptions = {
  OPEN: "Open",
  ON_HOLD: "On hold",
};

const unidentifiedPaymentStatusOptions = [
  {
    value: "OPEN",
    label: labelByUnidentifiedPaymentStatusOptions["OPEN"],
  },
  {
    value: "ON_HOLD",
    label: labelByUnidentifiedPaymentStatusOptions["ON_HOLD"],
  },
];

export const labelByField: Record<
  keyof Omit<InboundsFilter, "paymentType" | "selectedRowKeys">,
  string
> = {
  country: "Country",
  creationSource: "Creation source",
  externalId: "External ID",
  orderNumber: "Order Number",
  category: "Category",
  status: "Status",
  paymentMethod: "Payment method",
  accountingDate: "Accounting date",
  uploadDate: "Upload date",
  accountHolderName: "Account holder name",
  iban: "IBAN",
  amount: "Amount",
};

const countries = [
  { value: "AT", label: "Austria" },
  { value: "BE", label: "Belgium" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "IT", label: "Italy" },
  { value: "NL", label: "Netherlands" },
  { value: "PL", label: "Poland" },
  { value: "ES", label: "Spain" },
  { value: "SE", label: "Sweden" },
];

export const titleByPaymentType: Record<PaymentType, string> = {
  internal: "Other",
  successful: "Successful",
  unidentified: "Unidentified",
};

const Label = ({ text }: { text: string }) => (
  <span style={{ fontWeight: 400 }}>{text}</span>
);

const getFileName = () => `${new Date().getTime()}_inbound_payments_export`;
const usePaymentsForCsv = (
  filter: InboundsFilter,
  total: number,
  page: number
) =>
  useQuery([filter, total, page], fetchPaymentsForCsv(filter, total, page), {
    retry: 1,
    enabled: false,
  });
export function PaymentsOverviewFilter({
  total,
  page,
  selectedRowKeys,
  clearSelectedRowKeys,
}: {
  total: number;
  page: number;
  selectedRowKeys: Key[];
  clearSelectedRowKeys: () => void;
}) {
  const [filter, updateFilter] = useInboundsFilter();
  const [form] = Form.useForm();
  const { refetch: fetchCsvPayments } = usePaymentsForCsv(
    filter,
    selectedRowKeys.length ? PAGE_SIZE : total,
    selectedRowKeys.length ? page : 1
  );
  const getCsvRows = () => fetchCsvPayments().then((r) => r.data ?? []);
  const hasPermission = useHasPermissions();
  const handleReset = () => updateFilter({});

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [filter]);

  useEffect(() => {
    clearSelectedRowKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.paymentType]);

  const otherPaymentCategories = useOtherPaymentCategoryOptions();

  return (
    <Card
      extra={
        <Space wrap>
          <ExportToCsvButton
            maxItemsToExport={1000}
            getFileName={getFileName}
            total={total}
            selectedRowKeys={selectedRowKeys}
            getCsvRows={getCsvRows}
          />
          {filter.paymentType === "unidentified" &&
            hasPermission(
              EditUnidentifiedPermission.transitionPaymentUnidentifiedToInternalTransferBatch
            ) && (
              <MarkAsOther
                selectedRowKeys={selectedRowKeys}
                clearSelectedRowKeys={clearSelectedRowKeys}
              />
            )}
        </Space>
      }
      title={`Search for ${
        filter.paymentType ? titleByPaymentType[filter.paymentType] : ""
      } Inbound Payments`}
      style={{ width: "100%" }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={filter}
        onFinish={updateFilter}
      >
        <Space align="end" wrap>
          <Form.Item
            label={<Label text={labelByField.country} />}
            name="country"
          >
            <Select
              style={minWidth}
              size="large"
              showSearch
              placeholder="Country"
              allowClear
              filterOption={filterOption}
              options={countries}
            />
          </Form.Item>
          <Form.Item
            label={<Label text={labelByField.externalId} />}
            name="externalId"
          >
            <Input
              size="large"
              placeholder="External ID"
              style={minWidth}
              name="externalId"
            />
          </Form.Item>
          <Form.Item
            label={<Label text={labelByField.accountHolderName} />}
            name="accountHolderName"
          >
            <Input
              size="large"
              placeholder="Account holder name"
              style={minWidth}
              name="accountHolderName"
            />
          </Form.Item>
          <Form.Item label={<Label text={labelByField.iban} />} name="iban">
            <Input
              size="large"
              placeholder="IBAN"
              style={minWidth}
              name="iban"
            />
          </Form.Item>
          <Form.Item label={<Label text={labelByField.amount} />} name="amount">
            <InputNumber
              size="large"
              min={0}
              placeholder="Amount"
              style={minWidth}
              name="amount"
            />
          </Form.Item>
          {filter.paymentType === "internal" && (
            <Form.Item
              label={<Label text={labelByField.category} />}
              name="category"
            >
              <Select
                style={minWidth}
                size="large"
                showSearch
                placeholder="Category"
                allowClear
                filterOption={filterOption}
                options={otherPaymentCategories}
              />
            </Form.Item>
          )}
          <Form.Item
            label={<Label text={labelByField.paymentMethod} />}
            name="paymentMethod"
          >
            <Select
              style={minWidth}
              size="large"
              showSearch
              placeholder="Payment method"
              allowClear
              filterOption={filterOption}
              options={paymentMethodsOptions}
            />
          </Form.Item>
          {filter.paymentType === PaymentType.Unidentified && (
            <Form.Item
              label={<Label text={labelByField.status} />}
              name="status"
            >
              <Select
                style={minWidth}
                size="large"
                showSearch
                placeholder="Status"
                allowClear
                filterOption={filterOption}
                options={unidentifiedPaymentStatusOptions}
              />
            </Form.Item>
          )}
          <Form.Item
            label={<Label text={labelByField.accountingDate} />}
            name="accountingDate"
          >
            <DateRangePicker
              size="large"
              placeholder={["From", "To"]}
              allowClear
              format={dateFormat}
              allowEmpty={[true, true]}
              style={minWidth}
            />
          </Form.Item>
          <Form.Item
            label={<Label text={labelByField.uploadDate} />}
            name="uploadDate"
          >
            <DateRangePicker
              size="large"
              placeholder={["From", "To"]}
              allowClear
              format={dateFormat}
              allowEmpty={[true, true]}
              style={minWidth}
            />
          </Form.Item>
          {filter.paymentType === PaymentType.Successful && (
            <Form.Item
              label={<Label text={labelByField.creationSource} />}
              name="creationSource"
            >
              <Select
                style={minWidth}
                size="large"
                showSearch
                placeholder="Creation source"
                allowClear
                filterOption={filterOption}
                options={creationSourceOptions}
              />
            </Form.Item>
          )}
          {filter.paymentType === PaymentType.Successful && (
            <Form.Item
              label={<Label text={labelByField.orderNumber} />}
              name="orderNumber"
            >
              <Input
                size="large"
                placeholder="Order Number"
                style={minWidth}
                name="orderNumber"
              />
            </Form.Item>
          )}
          <Form.Item>
            <Space>
              <Button htmlType="reset" onClick={handleReset} size="large">
                Clear
              </Button>
              <Button
                icon={<SearchOutlined />}
                size="large"
                type="primary"
                htmlType="submit"
              />
            </Space>
          </Form.Item>
        </Space>
      </Form>
    </Card>
  );
}
