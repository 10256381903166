import { PaymentMethod } from "../pages/types";

type BankAccountDetails = {
  accountHolderName: string;
  bic: string;
  iban: string;
};

export type PaymentBase = BankAccountDetails & {
  createdOn: string;
  accountingDate: string;
  source?: string;
  recipientBank: string;
  paymentType: PaymentMethod;
  status?: string;
  errorMessage: string;
  amount: string;
  customerReference: string;
  retailCountry: string;
  externalId: string;
};

export type OrderDetails = {
  customerName: string;
  orderId: string;
  stockId: string;
};

export const hasOrderDetails = (
  obj: PaymentBase
): obj is OrderDetails & PaymentBase =>
  "customerName" in obj && "orderId" in obj && "stockId" in obj;
