import { formatAmount } from "../utils/amount";

export const searchOrdersAdapter = (data?: { entities: Array<any> }) => {
  const { entities = [] } = data || {};

  // TODO
  return entities.map((entity) => ({
    id: entity.id,
    orderId: entity.orderNumber,
    orderNumber: entity.orderNumber,
    stockId: entity.stockNumber,
    customerName: entity.customer
      ? `${entity.customer?.firstName} ${entity.customer?.lastName}`
      : "-",
    firstName: entity.customer?.firstName,
    lastName: entity.customer?.lastName,
    amount: formatAmount(
      entity.paymentDetails.paidAmount.amountMinorUnits,
      entity.paymentDetails.paidAmount.currencyCode,
      true
    ),
    remainingAmount: formatAmount(
      entity.paymentDetails.remainingAmount.amountMinorUnits,
      entity.paymentDetails.remainingAmount.currencyCode,
      true
    ),
    orderStatus: entity.state,
    country: entity.retailCountry,
    action: {
      id: entity.id,
      orderId: entity.orderNumber,
    },
  }));
};
