import { useCallback, useMemo, useState } from "react";
import { Table, Typography } from "antd";

import OrderMatchesSearchBar, {
  OrderSearchFormVM,
} from "../OrderMatchesSeachBar";
import { searchSelectors, suggestionsSelectors } from "../../constants/qa";
import { OrderMatchesActionsColumn } from "./OrderMatchesActionsColumn";
import { Column } from "./config";
import { AttachOrderModal } from "../modals";
import {
  useAssignUnidentifiedPaymentToOrder,
  usePaymentDetails,
} from "../../pages/hooks";
import { PaymentType } from "../../pages/types";

const { Title } = Typography;

interface OrderMatchesTableProps<T> {
  columns: Array<Column>;
  data: T[];
  title: string;
  onSearch?: (values: OrderSearchFormVM) => void;
}

export const OrderMatchesTable = <T extends { id: string; orderId: string }>({
  columns,
  data,
  title,
  onSearch,
}: OrderMatchesTableProps<T>) => {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);
  const assignPaymentToOrder = useAssignUnidentifiedPaymentToOrder();
  const [orderIds, setAttachOrderId] = useState<[string, string]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const closeModal = () => setShowModal(false);

  const onAttachOrder = useCallback(
    ({ id, orderId }: Pick<T, "id" | "orderId">) => {
      setAttachOrderId([id, orderId]);
      setShowModal(true);
    },
    []
  );

  const [searched, setSearched] = useState(!onSearch);

  const handleSearch = (values: OrderSearchFormVM) => {
    if (onSearch) {
      onSearch?.(values);
      !searched && setSearched(true);
    }
  };

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      {
        dataIndex: "action",
        key: "action",
        title: "Action",
        render: (_: unknown, props: T) => (
          <OrderMatchesActionsColumn
            qaSelector={
              onSearch ? searchSelectors.action : suggestionsSelectors.action
            }
            btnQaSelector={
              onSearch
                ? searchSelectors.attachBtn
                : suggestionsSelectors.attachBtn
            }
            onClick={() => onAttachOrder(props)}
          />
        ),
      },
    ],
    [columns, onAttachOrder, onSearch]
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <Title level={5}>{title}</Title>
      {onSearch && (
        <OrderMatchesSearchBar payment={payment} onSearch={handleSearch} />
      )}
      {searched && (
        <Table
          bordered
          dataSource={data}
          pagination={false}
          size="small"
          columns={columnsWithActions}
        />
      )}
      <AttachOrderModal
        paymentId={payment?.id}
        externalId={payment?.externalId}
        orderIds={orderIds}
        isVisible={showModal}
        isLoading={assignPaymentToOrder.isLoading}
        onOk={(paymentId, orderId) =>
          assignPaymentToOrder.mutate({
            paymentId,
            orderId,
          })
        }
        onCancel={closeModal}
      />
    </div>
  );
};
