import React from "react";
import { Button, Modal } from "antd";

import { paymentOverviewSelectors } from "../../constants/qa";

const TITLE = "Attach payment to an order";

interface AttachOrderModalProps {
  paymentId?: string;
  externalId?: string;
  orderIds?: [string, string];
  isVisible: boolean;
  isLoading: boolean;
  onOk: (paymentId: string, orderId: string) => void;
  onCancel: () => void;
}

const AttachOrderModal = (props: AttachOrderModalProps) => {
  const { externalId, paymentId, orderIds, isVisible, onOk, onCancel } = props;
  const [id, orderId] = orderIds || [];

  return (
    <Modal
      title={TITLE}
      visible={isVisible}
      onOk={() => paymentId && id && onOk(paymentId, id)}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          disabled={props.isLoading}
          onClick={onCancel}
          data-qa-selector={paymentOverviewSelectors.attachCancelBtn}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          loading={props.isLoading}
          onClick={() => paymentId && id && onOk(paymentId, id)}
          data-qa-selector={paymentOverviewSelectors.attachConfirmBtn}
        >
          Confirm
        </Button>,
      ]}
    >
      <p>
        You are about to attach payment {externalId} to order {orderId}
      </p>
    </Modal>
  );
};

export default AttachOrderModal;
