import { HttpMethods } from "./types";

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}

// TODO
export const callApi = async ({ method, url, domain, body }: any) => {
  const accessToken = getCookieByName("t");

  const headers = {
    "access-domain": domain,
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

const gqlServiceUrl = "/api/v1/retail-graphql-gateway";

type CallGql = any;
export const callGql: CallGql = ({ query = "", variables = {}, domain = "" }) =>
  callApi({
    method: HttpMethods.POST,
    url: gqlServiceUrl,
    domain,
    body: { query, variables },
  });
