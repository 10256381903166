import { PaymentMethod } from "../pages/types";
import { formatAmount } from "../utils/amount";
import { formatDate } from "../utils/date";
import { PaymentUploadStatus } from "../constants";
import { PaymentBase } from "./types";
import { CreationSource, formatSource } from "./formatSource";

export enum OtherPaymentType {
  Refund = "REFUND",
  Internal = "INTERNAL",
  NotApplicable = "NOT_APPLICABLE_PAYMENT",
  Legal = "LEGAL",
  RejectedOutbound = "REJECTED_OUTBOUND_PAYMENT",
  Logistic = "LOGISTIC",
  Overpayment = "OVERPAYMENT",
  Chargeback = "CHARGEBACK",
  OnHold = "ON_HOLD",
  RatenkaufFinancing = "RATENKAUF_FINANCING",
  OutgoingRefund = "OUTGOING_REFUND",
  DuplicateFinancingAmount = "DUPLICATE_FINANCING_AMOUNT",
  FinancingKickback = "FINANCING_KICKBACK",
  ChequePaymentsBatch = "CHEQUE_PAYMENTS_BATCH",
  PenaltyFees = "PENALTY_FEES",
}

export type OtherPayment = PaymentBase & {
  category: OtherPaymentType;
};

type FetchOtherPaymentResponse = {
  internalTransferPaymentById: {
    importDetails?: {
      recipientBankAccount?: string;
      customerPaymentReference?: string;
    };
    accountData: {
      bic: string;
      holder: string;
      iban: string;
    };
    accountingDate: string;
    amount: {
      amountMinorUnits: number;
      currencyCode: string;
    };
    category: OtherPaymentType;
    countryCode: string;
    createdOn: string;
    externalId: string;
    type: PaymentMethod;
    creationSource?: keyof typeof CreationSource;
  };
};

export const otherPaymentAdapter = ({
  internalTransferPaymentById: data,
}: FetchOtherPaymentResponse): OtherPayment => ({
  createdOn: formatDate(data.createdOn, true),
  accountingDate: formatDate(data.accountingDate),
  source: formatSource({
    creationSource: data.creationSource,
    hasExternalPaymentSystem: false,
  }),
  recipientBank: data.importDetails?.recipientBankAccount ?? "",
  customerReference: data.importDetails?.customerPaymentReference ?? "",
  paymentType: data.type,
  errorMessage: "",
  amount: formatAmount(
    data.amount.amountMinorUnits,
    data.amount.currencyCode,
    true
  ),
  accountHolderName: data.accountData?.holder,
  bic: data.accountData?.bic,
  iban: data.accountData?.iban,
  retailCountry: data.countryCode,
  externalId: data.externalId,
  category: data.category,
});
