import { Button } from "antd";

import { UnidentifiedPaymentStatus } from "../PaymentsOverview/PaymentsOverviewFilter";
import { paymentOverviewSelectors } from "../../constants/qa";
import { useUpdateUnidentifiedPayment } from "./useUpdateUnidentifiedPayment";

type PutPaymentOnHoldButtonProps = {
  paymentId: string;
  disabled?: boolean;
};
export const PutPaymentOnHoldButton = ({
  paymentId,
  disabled,
}: PutPaymentOnHoldButtonProps) => {
  const updateUnidentifiedPaymentMutation =
    useUpdateUnidentifiedPayment(paymentId);

  const putPaymentOnHold = () =>
    updateUnidentifiedPaymentMutation.mutate({
      status: UnidentifiedPaymentStatus.OnHold,
    });
  return (
    <>
      <Button
        type="primary"
        disabled={disabled || updateUnidentifiedPaymentMutation.isLoading}
        loading={updateUnidentifiedPaymentMutation.isLoading}
        onClick={putPaymentOnHold}
        data-qa-selector={paymentOverviewSelectors.putOnHoldButton}
      >
        Put on hold
      </Button>
    </>
  );
};
