import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { stringify } from "query-string";

import { PaymentType } from "../../types";
import { routePathByPage } from "../../../routes";
import { useHasPermissions } from "../../../hooks";
import { paymentsOverviewSelectors } from "../../../constants/qa";
import { EditUnidentifiedPermission } from "../../../constants";
import {
  adaptInboundsFilterToQueryParams,
  useInboundsFilter,
} from "../PaymentsOverviewFilter";

interface PaymentActionsColumnProps {
  paymentId: string;
  externalId: string;
  orderId?: string;
  qaSelector: string;
  page: number | string;
}

export const PaymentActionsColumn = ({
  paymentId,
  externalId,
  orderId,
  qaSelector,
  page,
}: PaymentActionsColumnProps) => {
  const history = useHistory();
  const hasPermissions = useHasPermissions();
  const { lang, paymentType } =
    useParams<{ lang: string; paymentType: string }>();
  const showEdit =
    paymentType === PaymentType.Unidentified &&
    hasPermissions(Object.values(EditUnidentifiedPermission));

  const path = generatePath(routePathByPage.details, {
    lang,
    paymentType,
    id: paymentId,
  });
  const params = stringify({ page, orderId, externalId });

  const [filter] = useInboundsFilter();
  const onViewClick = () =>
    history.push(`${path}?${params}`, {
      filter: adaptInboundsFilterToQueryParams(filter),
      page,
    });
  const onEditClick = () =>
    history.push(`${path}/edit?${params}`, {
      filter: adaptInboundsFilterToQueryParams(filter),
      page,
    });

  return (
    <div data-qa-selector={qaSelector}>
      <Button
        type="link"
        shape="round"
        size="small"
        icon={<EyeOutlined />}
        onClick={onViewClick}
        data-qa-selector={paymentsOverviewSelectors.viewButton}
      />
      {showEdit && (
        <Button
          type="link"
          shape="round"
          size="small"
          icon={<EditOutlined />}
          onClick={onEditClick}
          data-qa-selector={paymentsOverviewSelectors.editButton}
        />
      )}
    </div>
  );
};
